import { routerPaths } from '~/shared/config/router';

export const ACCOUNT_NAVIGATION = [
  {
    title: 'Profile Details',
    links: [
      {
        label: 'Personal data',
        href: routerPaths.account,
        icon: '/',
      },
      {
        label: 'About Me',
        href: routerPaths.account,
        icon: '',
      },
      {
        label: 'Character & traits',
        href: routerPaths.account,
        icon: '',
      },
      {
        label: 'Christian lifestyle & habits',
        href: routerPaths.account,
        icon: '',
      },
      {
        label: 'My lifestyle',
        href: routerPaths.account,
        icon: '',
      },
    ],
  },
  {
    title: 'My Account',
    links: [
      {
        label: 'Subscription',
        href: routerPaths.accountSubscription,
        icon: '',
      },
      {
        label: 'Change Password',
        href: routerPaths.accountChangePassword,
        icon: '',
      },
      {
        label: 'Change Number',
        href: routerPaths.accountChangePhone,
        icon: '',
      },
      {
        label: 'Refer a Friend',
        href: routerPaths.accountRefer,
        icon: '',
      },
      {
        label: 'Delete Account',
        href: routerPaths.account,
        icon: '',
      },
    ],
  },
];